import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide4/image/imgPortadaGuia4.png"
import img1 from "@components/pageGuide/guides/guide4/image/img4.png"
import slider1 from "@components/pageGuide/guides/guide4/image/slider1.png"
import slider2 from "@components/pageGuide/guides/guide4/image/slider2.png"
import slider3 from "@components/pageGuide/guides/guide4/image/slider3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
      ¿Cómo trabajar la gestión de leads y lograr convertir?
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      Según HubSpot, las empresas con prácticas de generación y gestión de leads
      tienen una tasa de logro de cuota de ventas 9.3% más alta. Por eso, aquí
      aprenderás todos los pasos que necesitas realizar para aplicar un proceso
      de gestión adecuada de tus nuevos contactos.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    La gestión de leads es un sistema complejo compuesto por varios procesos
    diferentes, cada uno de los cuales es esencial para lograr un objetivo en
    común, convertir. Pero, muy aparte del talento, también debemos enfocarnos
    utilizar la tecnología adecuada para agilizar esta gestión.
    <br /> <br />
    Por eso, en esta guía no solo aprenderás a cómo gestionar tus leads
    correctamente. Sino también vas a comprender cuáles son los requisitos que
    debes considerar al momento de adquirir una plataforma especializada en este
    tipo de procesos.
  </p>
)

const data = {
  start: {
    support: "Guía | Telemarketing",
    title: title,
    description: textDescription,
    classtext: "guide__description4",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía-para-una-buena-experiencia-de-compra",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Analizar fuentes de leads.",
      },
      {
        key: 2,
        text: "Cómo personalizar el proceso de leads.",
      },
      {
        key: 3,
        text: "Diseño de un sistema de puntuación.",
      },
      {
        key: 4,
        text: "Enrutamiento de información del lead.",
      },
      {
        key: 5,
        text: "Qué tecnología utilizar.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
